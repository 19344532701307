import React from 'react'
import styled from 'styled-components'

type Props = {
	variant: 'h1' | 'h2' | 'h3' | 'h4' | 'subheading'
	useCurrentColor?: boolean
	center?: boolean
}

const Heading = styled.h1<Props>`
	color: ${(props) => (props.useCurrentColor ? 'currentColor' : props.theme.secondary)};
	letter-spacing: 1.4px;
	margin-bottom: 1rem;

	font-family: ${(props) => {
		switch (props.variant) {
			case 'h1':
			case 'h3':
			case 'h4':
			case 'subheading':
				return props.theme.fonts.montserrat
			default:
				return props.theme.fonts.dosis
		}
	}};
	font-size: ${(props) => {
		switch (props.variant) {
			case 'h1':
				return '40px'
			case 'h2':
				return '40px'
			case 'h3':
				return '32px'
			case 'h4':
				return '32px'
			case 'subheading':
				return '18px'
		}
	}};
	font-weight: ${(props) => {
		switch (props.variant) {
			case 'h1':
				return '900'
			case 'h2':
				return '800'
			case 'h3':
				return 'bold'
			case 'h4':
				return '800'
			case 'subheading':
				return '600'
		}
	}};
	line-height: ${(props) => {
		switch (props.variant) {
			case 'h1':
			case 'subheading':
				return '1.4'
			case 'h2':
				return '1.2'
			case 'h3':
			case 'h4':
				return '1.25'
		}
	}};
	text-transform: ${(props) => {
		switch (props.variant) {
			case 'subheading':
				return 'uppercase'
			default:
				return 'unset'
		}
	}};
	text-align: ${(props) => (props.center ? 'center' : 'left')};

	a {
		color: currentColor;
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			color: ${(props) => props.theme.primary};
		}
	}
`

export { Heading }
