import React, { FC } from 'react'
import styled from 'styled-components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Contact } from './Contact'
import { breakpoints } from '../helpers'

const FooterContact: FC = () => {
	return (
		<Wrapper>
			<Grid>
				<Row middle='xs' center='xs'>
					<Col xs>
						<Contact />
					</Col>
				</Row>
			</Grid>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background: ${(props) => props.theme.primaryContrast};
	color: white;
	padding: 3.3rem 0;

	${breakpoints.mobile} {
		padding: 2rem 0;
	}
`

export { FooterContact }
