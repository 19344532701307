export const API_ENDPOINT = '/.netlify/functions'
export const DATE_FORMAT = 'dd/MM/yyyy'
export const SENDGRID_API_KEY = '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'
export const SENDGRID_FROM_ADDRESS = 'info@insure-anytime.com'
export const SENDGRID_TEMPLATE_KEYS = {
	renew: {
		customer: 'd-e55e6c03055b4a9c9dea111bc79a6c82',
		broker: 'd-56c5e0c4f5d1440cbd8e0f6db31a4f4c',
	},
	report: {
		customer: 'd-38bb804b5cbc464eb74caf490d911ed3',
		broker: 'd-51018bb638814c2e9496ecc3c3c88fd2',
	},
	quote: {
		customer: 'd-8851d37a3b7b4ae49f2d81eeb75b8f69',
		broker: 'd-a07d0f5ccaaf45aa8a44a7c75b416806',
	},
	contact: {
		admins: 'd-59b6dce70c9a42268d68c22a86945953',
	},
}
