import React from 'react'
import { Grid, Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Wrapper = styled.footer`
	background: ${(props) => props.theme.secondary};
	color: white;
	font-size: 16px;
	font-weight: 500;
	font-family: ${(props) => props.theme.fonts.montserrat};
	padding: 1rem 0;
`

const Footer: React.FC = () => {
	return (
		<Wrapper>
			<Grid>
				<Row center='xs' middle='xs'>
					<FooterBlock>
						<FooterLink to={'/privacy'}>Privacy policy</FooterLink>
					</FooterBlock>
					<FooterBlock>&copy; insureanytime {new Date().getFullYear()}</FooterBlock>
				</Row>
			</Grid>
		</Wrapper>
	)
}

const FooterBlock = styled.div`
	margin: 0 0.5rem;
`

const FooterLink = styled(Link)`
	color: inherit;
`

export { Footer }
