import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../helpers'
import { Link } from 'gatsby'
import { Grid } from 'react-styled-flexboxgrid'
import Logo from '../../img/logo.svg'
import { Button } from '../primitives'
import Email from '../../img/email.svg'
import { PageContext } from '../Layout'
import { VisuallyHidden } from '../VisuallyHidden'

const Mobile: React.FC = () => {
	const {
		layout: { showRenewLinkOnMobile },
	} = useContext(PageContext)

	return (
		<MobileWrapper>
			<MobileInner>
				<Link to='/'>
					<StLogo />
					<VisuallyHidden>Insure Anytime</VisuallyHidden>
				</Link>
				<Button
					as={Link}
					to={'/contact-us'}
					xp={0}
					yp={0}
					variant='transparent'
					style={{ justifySelf: 'flex-end' }}
					className='gtm-nav-contact'
				>
					<Email />
					<VisuallyHidden>Contact us</VisuallyHidden>
				</Button>
			</MobileInner>
			{showRenewLinkOnMobile && (
				<Renew as={Link} to='/get-a-quote' id='gtm-nav-mobile-renew' className='gtm-nav-renew'>
					Get a Quote
				</Renew>
			)}
		</MobileWrapper>
	)
}

const MobileWrapper = styled.div`
	background: ${(props) => props.theme.gray1};

	${breakpoints.desktop} {
		display: none;
	}
`

const MobileInner = styled(Grid)`
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding-bottom: 1.5rem;
	padding-top: 1.5rem;
	position: relative;
	z-index: 100;
`

const StLogo = styled(Logo)`
	${breakpoints.mobile} {
		height: 55px;
		width: auto;
	}
`

const Renew = styled(Button)`
	border-radius: 0;
	text-align: center;
	width: 100%;
`

export { Mobile }
