import React, { FC, Fragment } from 'react'
import { FieldValues } from 'react-hook-form'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { camelCaseToTitleCase } from '../../../helpers'
import { Label } from './Group'
import { format } from 'date-fns'
import { DATE_FORMAT } from '../../../constants'

type Props = {
	fields?: FieldValues
}

const FormReview: FC<Props> = ({ fields, children }) => {
	if (!fields) {
		return null
	}

	const filteredFields = Object.keys(fields).reduce<{ label: string; value: string | JSX.Element | JSX.Element[] }[]>(
		(acc, field: string) => {
			const label = camelCaseToTitleCase(field)
			const value = fields[field]

			if (['Agree'].includes(label) || !fields[field]) {
				return acc
			}

			if (typeof value === 'object' && value instanceof FileList && value.length > 0) {
				const fileValues = Object.keys(value).map((key: any) => {
					const { name } = value[key] as File
					return (
						<Fragment key={name}>
							{name}
							<br />
						</Fragment>
					)
				})

				acc.push({
					label,
					value: fileValues,
				})

				return acc
			}

			if (typeof value === 'object' && value instanceof Date) {
				acc.push({
					label,
					value: format(value, DATE_FORMAT),
				})

				return acc
			}

			acc.push({
				label,
				value,
			})

			return acc
		},
		[],
	)

	return (
		<Grid>
			{children}
			<Row center='md'>
				<Col md={8}>
					{filteredFields.map(({ label, value }) => (
						<Row key={`${label}-${value.toString()}`}>
							<Col xs={12} md={3}>
								<Label as='div'>{label}</Label>
							</Col>
							<Col xs={12} md style={{ marginBottom: '1rem' }}>
								{value}
							</Col>
						</Row>
					))}
				</Col>
			</Row>
		</Grid>
	)
}

export { FormReview }
