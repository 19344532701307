import React, { FC } from 'react'
import styled from 'styled-components'
import { Grid } from 'react-styled-flexboxgrid'
import { breakpoints } from '../helpers'

const MessageBox: FC = ({ children }) => {
	if (!children) {
		return null
	}

	return (
		<StMessage>
			<Grid>{children}</Grid>
		</StMessage>
	)
}

const StMessage = styled.div`
	background: white;
	text-align:center;
	color: ${(props) => props.theme.secondary}
	font-weight: 500;
	padding: 3rem 0;
	margin: 5rem 0;
	
	${breakpoints.mobile} {
		padding: 1.5rem 0;
		margin: 2rem 0;
	}
`

export { MessageBox }
