import { createGlobalStyle } from 'styled-components'
import React, { FC } from 'react'
import { Reset } from 'styled-reset'
import { theme } from './theme'

const Globals = createGlobalStyle`
	* {
		box-sizing: border-box;
	}

	html,
	body {
		font-family: ${theme.fonts.montserrat};
		font-size: 18px;
		color: ${theme.secondary};
		letter-spacing: 1px;
		line-height: 1.4;
	}

	p,
	ul {
		line-height: 1.5;
		margin-bottom: 1rem;
	}
	
	input[type=text],
	input[type=email],
	input[type=password],
	textarea {
		-webkit-appearance: none;
	}
	
	strong {
		font-weight: bold;
	}

	#gatsby-focus-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
	}
`

const GlobalStyles: FC = () => (
	<>
		<Reset />
		<Globals />
	</>
)

export { GlobalStyles }
