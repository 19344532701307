import React, { FC, FormEvent, HTMLProps } from 'react'
import styled from 'styled-components'
import { Heading } from '../Heading'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Hr } from '../Hr'
import { breakpoints } from '../../../helpers'

type Props = {
	onSubmit: (e: FormEvent) => void
} & HTMLProps<HTMLFormElement>

const Form: FC<Props> = ({ onSubmit, children, as, ref, ...rest }) => {
	return (
		<StForm {...rest} onSubmit={onSubmit}>
			<FormBody>{children}</FormBody>
		</StForm>
	)
}

const StForm = styled.form`
	padding: 2rem 0 3rem;

	${breakpoints.mobile} {
		padding: 1.5rem 0;
	}
`

const FormBody = styled.div`
	//padding: 3rem 0 0;

	${breakpoints.mobile} {
		//padding: 1rem 0 0;
	}
`

type FormHeadingProps = {
	center?: boolean
	subheading?: string
	p?: string | JSX.Element
}

const FormHeading: FC<FormHeadingProps> = ({ center, subheading, p, children }) => {
	return (
		<StFormHeading>
			<Heading as='h1' variant='h3' center={center}>
				{children}
			</Heading>
			{subheading && (
				<Heading variant='subheading' as='h4' center={center}>
					{subheading}
				</Heading>
			)}
			{p && <p style={{ textAlign: center ? 'center' : 'left' }}>{p}</p>}
		</StFormHeading>
	)
}

const StFormHeading = styled(Grid)`
	padding-bottom: 3rem;

	${breakpoints.mobile} {
		padding-bottom: 1.5rem;
	}
`

const FormFooter: FC = ({ children }) => {
	return (
		<StFooter>
			<Grid>
				<Row middle={'xs'}>
					<Col xs={false} md>
						<Hr />
					</Col>
					<StChildrenCol xs>{children}</StChildrenCol>
				</Row>
			</Grid>
		</StFooter>
	)
}

const StFooter = styled.div``

const StChildrenCol = styled(Col)`
	${breakpoints.desktop} {
		flex-grow: 0;
	}
`

type FormStepProps = {
	visible: boolean
}

const FormStep = styled.section<FormStepProps>`
	display: ${({ visible }) => (visible ? 'block' : 'none')};
`

export { Form, FormFooter, FormHeading, FormStep }
