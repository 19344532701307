import { theme } from './theme'

const breakpoints = {
	desktop: `@media only screen and (min-width: ${theme.flexboxgrid.breakpoints.md}em)`,
	mobile: `@media only screen and (max-width: 69.9375em)`,
}

const camelCaseToTitleCase = (text?: string) => {
	if (!text) {
		return ''
	}

	const result = text.replace(/([A-Z])/g, ' $1')
	return result.charAt(0).toUpperCase() + result.slice(1)
}

const pushToDataLayer = (key: string, value: string) => {
	if (!window.dataLayer) {
		console.error('dataLayer not available')
		return
	}

	const newLayer = { [`${key}`]: value }

	window.dataLayer.push(newLayer)
}

export { breakpoints, camelCaseToTitleCase, pushToDataLayer }
