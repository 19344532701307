import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Desktop } from './Desktop'
import { Mobile } from './Mobile'

type Props = {
	showBottomBorder?: boolean
}

const Nav: FC<Props> = ({ showBottomBorder }) => {
	return (
		<Wrapper showBottomBorder={showBottomBorder}>
			<Desktop />
			<Mobile />
		</Wrapper>
	)
}

const Wrapper = styled.div<{ showBottomBorder?: boolean }>`
	position: sticky;
	top: 0;
	transition: border-color 300ms;
	z-index: 1000;

	${(props) =>
		props.showBottomBorder &&
		css`
			border-bottom: 1px solid ${props.theme.gray2};
		`}
`

export { Nav }
