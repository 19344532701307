import React, { createRef, FC, HTMLProps, RefObject } from 'react'
import styled from 'styled-components'
import ErrorIcon from '../../../img/error.svg'
import CheckIcon from '../../../img/check.svg'
import { Group, GroupProps } from './Group'

type Props = {
	status?: 'successful' | 'error'
	inputRef?: any
} & GroupProps &
	HTMLProps<HTMLTextAreaElement>

const TextArea: FC<Props> = ({ status, id, message, label, helper, ref, inputRef, as, ...rest }) => {
	return (
		<Group id={id} label={label} message={message} helper={helper}>
			<InputWrapper>
				<StTextArea id={id} ref={inputRef} {...rest} />
				{status === 'successful' && <CheckIcon />}
				{status === 'error' && <ErrorIcon />}
			</InputWrapper>
		</Group>
	)
}

const InputWrapper = styled.div`
	position: relative;

	svg {
		position: absolute;
		right: 1rem;
		top: 1rem;
	}
`

const StTextArea = styled.textarea`
	-webkit-appearance: none;
	border: 1px solid ${(props) => props.theme.secondary};
	box-shadow: none;
	border-radius: 0;
	background: none;
	color: ${(props) => props.theme.primary};
	display: block;
	letter-spacing: 1.4px;
	font-family: ${(props) => props.theme.fonts.dosis};
	font-size: 0.975rem;
	padding: 1rem 3rem 1rem 1.5rem;
	width: 100%;
	resize: none;
	min-height: 345px;

	&::placeholder {
		color: ${(props) => props.theme.gray3};
	}
`

export { TextArea }
