import React, { FC } from 'react'
import { Button, ButtonProps } from '../Button'
import { Link } from 'gatsby'
import ChevronLeftIcon from '../../../img/chevron-left.svg'
import styled from 'styled-components'
import { breakpoints } from '../../../helpers'
import { Grid } from 'react-styled-flexboxgrid'

type Props = {
	to?: string
	onClick?: (e: any) => void
	grid?: boolean
}

const Back: FC<Props> = ({ to, onClick, grid = true }) => {
	const commonProps: ButtonProps = {
		xp: 0,
		variant: 'transparent',
	}

	const body = (
		<>
			<ChevronLeftIcon style={{ marginRight: '0.75rem' }} />
			Back
		</>
	)

	const Wrapper = grid ? Grid : 'div'

	return (
		<Wrapper>
			{to ? (
				<StButton type='button' to={to} as={Link} onClick={onClick} {...commonProps}>
					{body}
				</StButton>
			) : (
				<StButton type='button' onClick={onClick} {...commonProps}>
					{body}
				</StButton>
			)}
		</Wrapper>
	)
}

const StButton = styled(Button)`
	margin-bottom: 2rem;

	${breakpoints.mobile} {
		margin-bottom: 1.5rem;
	}
`

export { Back }
