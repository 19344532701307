import React from 'react'
import styled, { css } from 'styled-components'
import { breakpoints } from '../helpers'

type Props = {
	backgroundColor?: string
	removePadding?: boolean
}

const Main = styled.main<Props>`
	background-color: ${(props) => props.backgroundColor ?? 'white'};
	flex: 1;
	padding-top: 3rem;
	padding-bottom: 3rem;

	${breakpoints.mobile} {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	${(props) =>
		props.removePadding &&
		css`
			padding: 0;

			${breakpoints.mobile} {
				padding: 0;
			}
		`}
`

export { Main }
