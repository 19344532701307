import React, { FC, HTMLProps } from 'react'
import styled from 'styled-components'
import CheckboxIcon from '../../../img/checkbox.svg'
import CheckboxCheckedIcon from '../../../img/checkbox-checked.svg'
import AlertErrorIcon from '../../../img/alert-error.svg'
import { Grid } from 'react-styled-flexboxgrid'
import { Message } from './Message'
import { breakpoints } from '../../../helpers'

type CheckboxPrimeProps = HTMLProps<HTMLInputElement>

const CheckboxPrime = React.forwardRef<HTMLInputElement, CheckboxPrimeProps>(
	({ as, type, className, checked, ...rest }, ref) => (
		<CheckboxContainer>
			<HiddenCheckbox tabIndex={-1} checked={checked} {...rest} ref={ref} />
			{checked ? <CheckboxCheckedIcon /> : <CheckboxIcon />}
		</CheckboxContainer>
	),
)

const CheckboxContainer = styled.div`
	display: inline-block;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	border: 0;
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`

type CheckboxProps = {
	id: string
	labelText: string | JSX.Element
	message?: string
	showMessage?: boolean
} & HTMLProps<HTMLInputElement>

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
	({ id, labelText, message, showMessage, ...rest }, ref) => {
		return (
			<Wrapper>
				<Body messageShowing={!!(message && showMessage)}>
					<Grid>
						<CheckboxLabel>
							<CheckboxPrime id={id} {...rest} ref={ref} />
							<CheckboxLabelText>{labelText}</CheckboxLabelText>
						</CheckboxLabel>
					</Grid>
				</Body>
				{message && showMessage && (
					<CheckboxMessageWrapper>
						<CheckboxMessage>
							<AlertErrorIcon style={{ marginRight: '1rem' }} />
							{message}
						</CheckboxMessage>
					</CheckboxMessageWrapper>
				)}
			</Wrapper>
		)
	},
)

const Wrapper = styled.div`
	margin: 2rem 0;

	${breakpoints.desktop} {
		margin: 4rem 0;
	}
`

const Body = styled.div<{ messageShowing?: boolean }>`
	background: ${(props) => props.theme.quaternary};
	padding: 3.5rem 0;

	${(props) =>
		props.messageShowing &&
		`
		${breakpoints.mobile} {
			padding-bottom: 0;
		}
	`}
`

const CheckboxLabel = styled.label`
	display: flex;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.4;

	${breakpoints.mobile} {
		padding-left: 1rem;
	}
`

const CheckboxLabelText = styled.div`
	padding-left: 1.5rem;
`

const CheckboxMessageWrapper = styled(Grid)`
	padding-top: 1rem;

	${breakpoints.mobile} {
		background: ${(props) => props.theme.quaternary};
		padding-bottom: 2rem;
	}
`

const CheckboxMessage = styled(Message)`
	padding: 0;
	display: flex;
	align-items: center;
`

export { CheckboxPrime, Checkbox }
