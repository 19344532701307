import React, { createContext } from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { Footer, Main, Nav } from './'
import { FooterContact } from './FooterContact'
import { theme } from '../theme'
import { GlobalStyles } from '../GlobalStyles'

type Props = {
	showFooterContact?: boolean
	useGrayBG?: boolean
	showRenewLinkOnMobile?: boolean
	padMain?: boolean
}

type PageContext = {
	meta: SiteMeta
	layout: {
		showFooterContact?: boolean
		useGrayBG?: boolean
		showRenewLinkOnMobile?: boolean
	}
}

type SiteMeta = {
	title: string
	description: string
	email: string
	facebook: string
	instagram: string
}

const initialPageContext: PageContext = {
	meta: { title: '', description: '', email: '', facebook: '', instagram: '' },
	layout: {},
}

const PageContext = createContext<PageContext>(initialPageContext)

const Layout: React.FC<Props> = ({ showFooterContact, showRenewLinkOnMobile, useGrayBG, padMain = true, children }) => {
	type Data = { site: { siteMetadata: SiteMeta } }

	const {
		site: { siteMetadata },
	} = useStaticQuery<Data>(graphql`
		query HeadingQuery {
			site {
				siteMetadata {
					title
					description
					email
					facebook
					instagram
				}
			}
		}
	`)

	const context: PageContext = {
		meta: siteMetadata,
		layout: {
			showFooterContact,
			showRenewLinkOnMobile,
			useGrayBG,
		},
	}

	return (
		<PageContext.Provider value={context}>
			<ThemeProvider theme={theme}>
				<Helmet>
					<title>{siteMetadata.title}</title>
					<meta name='description' content={siteMetadata.description} />
					<meta property='og:title' content={siteMetadata.title} />
					<meta property='og:url' content='/' />
					<meta property='og:image' content='/img/og-image.jpg' />
				</Helmet>
				<GlobalStyles />
				<Nav showBottomBorder={useGrayBG} />
				<Main backgroundColor={useGrayBG ? theme.gray1 : 'white'} removePadding={!padMain}>
					{children}
				</Main>
				{showFooterContact && <FooterContact />}
				<Footer />
			</ThemeProvider>
		</PageContext.Provider>
	)
}

export { Layout, PageContext }
