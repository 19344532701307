import React, { FC, useContext } from 'react'
import { Heading } from './primitives'
import EmailIcon from '../img/email.svg'
import FacebookIcon from '../img/social/facebook.svg'
import InstagramIcon from '../img/social/instagram.svg'
import styled from 'styled-components'
import { breakpoints } from '../helpers'
import { PageContext } from './Layout'
import { VisuallyHidden } from './VisuallyHidden'

const Contact: FC = () => {
	const {
		meta: { email, facebook, instagram },
	} = useContext(PageContext)

	return (
		<Content>
			<Heading variant='h3' useCurrentColor>
				Contact Us
			</Heading>
			<EmailWrapper>
				<EmailIcon />
				<EmailLabel>
					Email<span>:</span>
				</EmailLabel>{' '}
				<a href={`mailto:${email}`} target='_blank' id='gtm-footer-email'>
					{email}
				</a>
			</EmailWrapper>
			<SocialWrapper>
				<SocialLink href={facebook} target='_blank' rel='noopener' id='gtm-footer-facebook'>
					<FacebookIcon />
					<VisuallyHidden>Facebook</VisuallyHidden>
				</SocialLink>
				<SocialLink href={instagram} target='_blank' rel='noopener' id='gtm-footer-instagram'>
					<InstagramIcon />
					<VisuallyHidden>Instagram</VisuallyHidden>
				</SocialLink>
			</SocialWrapper>
		</Content>
	)
}

const Content = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	text-align: center;
`

const EmailWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;

	${breakpoints.mobile} {
		flex-flow: column;
	}

	svg {
		${breakpoints.desktop} {
			margin-right: 1rem;
		}

		path {
			fill: white;
		}
	}

	a {
		text-decoration: none;
		color: currentColor;
	}
`

const EmailLabel = styled.div`
	font-weight: bold;
	display: inline-block;

	${breakpoints.desktop} {
		margin-right: 0.5rem;
	}

	${breakpoints.mobile} {
		padding: 1.5rem 0 0.5rem;
	}

	span {
		${breakpoints.mobile} {
			//display: none;
		}
	}
`

const SocialWrapper = styled.div``

const SocialLink = styled.a`
	padding: 0.5rem;

	svg {
		pointer-events: none;
	}
`

export { Contact }
