const theme = {
	body: 'black',
	primary: '#522DD2', // purple
	primaryContrast: '#5B39CA', // purple, contrast
	secondary: '#2F2357', // dark purple
	tertiary: '#0C5DCA', // blue
	quaternary: '#C3E47C', // green
	error: '#FF0000',
	boxShadow: 'rgba(0, 0, 0, 0.2)',
	gray1: '#FBFBFB',
	gray2: '#BDBDBD',
	gray3: '#D7D7D7',
	gray4: '#A5A5A5',
	fonts: {
		montserrat: 'Montserrat, sans-serif',
		dosis: 'Dosis, sans-serif',
	},
	flexboxgrid: {
		gridSize: 12,
		gutterWidth: 2, // rem
		outerMargin: 1, // rem
		mediaQuery: 'only screen',
		container: {
			sm: 46, // em
			md: 61, // em
			lg: 76, //em
		},
		breakpoints: {
			xs: 0, //em
			sm: 55, //em
			md: 70, //em
			lg: 90, //em
		},
	},
}

export { theme }
