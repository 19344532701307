import React, { FC, HTMLProps } from 'react'
import styled from 'styled-components'
import Dropdown, { ReactDropdownProps } from 'react-dropdown'
import { Group, GroupProps } from './Group'
import ChevronUp from '../../../img/chevron-up.svg'
import ChevronDown from '../../../img/chevron-down.svg'

type Props = GroupProps &
	HTMLProps<HTMLSelectElement> &
	ReactDropdownProps & {
		status?: 'successful' | 'error'
	}

const Select: FC<Props> = ({ status, id, message, label, helper, ref, as, ...rest }) => {
	return (
		<Group id={id} label={label} message={message} helper={helper}>
			<SelectWrapper>
				<Dropdown {...rest} arrowOpen={<ChevronUp />} arrowClosed={<ChevronDown />} />
			</SelectWrapper>
		</Group>
	)
}

const SelectWrapper = styled.div`
	.Dropdown-root {
		position: relative;
	}

	.Dropdown-control {
		position: relative;
		overflow: hidden;
		background: none;
		border: 1px solid ${(props) => props.theme.secondary};
		box-sizing: border-box;
		color: ${(props) => props.theme.secondary};
		cursor: pointer;
		outline: none;
		padding: 1rem 3rem 1rem 1.5rem;
		transition: all 300ms ease;
		font-family: ${(props) => props.theme.fonts.dosis};
		font-size: 0.975rem;
		letter-spacing: 1.4px;

		&.Dropdown-disabled {
			color: ${(props) => props.theme.gray4};

			svg path {
				fill: ${(props) => props.theme.gray4};
			}
		}
	}

	.Dropdown-control:hover {
		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
	}

	svg {
		position: absolute;
		right: 1.5rem;
		top: 50%;
		transform: translateY(-50%);
	}

	.is-open .Dropdown-arrow {
		border-color: transparent transparent #999;
		border-width: 0 5px 5px;
	}

	.Dropdown-menu {
		background-color: white;
		border: 1px solid ${(props) => props.theme.secondary};
		box-sizing: border-box;
		margin-top: -1px;
		max-height: 200px;
		overflow-y: auto;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 1000;
		-webkit-overflow-scrolling: touch;
	}

	.Dropdown-menu .Dropdown-group > .Dropdown-title {
		padding: 8px 10px;
		color: currentColor;
		font-weight: bold;
	}

	.Dropdown-option {
		box-sizing: border-box;
		color: currentColor;
		padding: 1rem 1.5rem;
		cursor: pointer;
		display: block;
		font-family: ${(props) => props.theme.fonts.dosis};
		font-size: 0.975rem;
		letter-spacing: 1.4px;
	}

	.Dropdown-option + .Dropdown-option {
		border-top: 1px solid ${(props) => props.theme.secondary};
	}

	.Dropdown-option:hover {
		background: ${(props) => props.theme.primary};
		border-color: ${(props) => props.theme.primary};
		color: white;
	}

	.Dropdown-option.is-selected {
		background: ${(props) => props.theme.primary};
		border-color: ${(props) => props.theme.primary};
		color: white;
	}

	.Dropdown-noresults {
		box-sizing: border-box;
		color: #ccc;
		cursor: default;
		display: block;
		padding: 8px 10px;
	}
`

export { Select, SelectWrapper }
