import React from 'react'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { breakpoints } from '../../helpers'
import Logo from '../../img/logo.svg'
import Email from '../../img/email.svg'
import { Button } from '../primitives'
import { VisuallyHidden } from '../VisuallyHidden'

type Props = {}

const Desktop: React.FC<Props> = () => {
	return (
		<DesktopWrapper>
			<Grid>
				<Row between='xs' middle='xs'>
					<Col xs>
						<Link to='/'>
							<Logo />
							<VisuallyHidden>Insure Anytime</VisuallyHidden>
						</Link>
					</Col>
					<Col xs>
						<Nav>
							<ContactButton
								variant='transparent'
								as={Link}
								to='/contact-us'
								xp={0}
								style={{ display: 'flex', alignItems: 'center' }}
								className='gtm-nav-contact'
							>
								<Email style={{ marginRight: '0.5rem' }} />
								Contact us
							</ContactButton>
							<Button variant='primary' as={Link} to='/get-a-quote' className='gtm-nav-renew'>
								Get a Quote
							</Button>
						</Nav>
					</Col>
				</Row>
			</Grid>
		</DesktopWrapper>
	)
}

const ContactButton = styled(Button)`
	display: flex;
	align-items: center;

	&:hover svg path {
		fill: ${(props) => props.theme.primary};
	}

	svg path {
		transition: fill 300ms;
	}
`

const DesktopWrapper = styled.div`
	background: ${(props) => props.theme.gray1};
	padding-bottom: 1.5rem;
	padding-top: 1.5rem;

	${breakpoints.mobile} {
		display: none;
	}
`

const Nav = styled.nav`
	align-items: center;
	display: flex;
	justify-content: flex-end;

	& > * {
		margin-left: 2.5rem;
	}
`

export { Desktop }
