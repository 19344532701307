import React, { FC, HTMLAttributes, HTMLProps } from 'react'
import styled from 'styled-components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { breakpoints } from '../helpers'

type Props = {
	image: HTMLProps<HTMLImageElement>
}

const TextImage: FC<Props> = ({ image, children }) => {
	return (
		<Wrapper>
			<Grid>
				<Row>
					<Col xs={12} md={5}>
						<Text>{children}</Text>
					</Col>
				</Row>
			</Grid>
			<ImageWrapper style={{ backgroundImage: `url(${image.src})` }} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: relative;
	overflow: hidden;
`

const Text = styled.div`
	padding: 8rem 0;

	${breakpoints.mobile} {
		padding: 2rem 0;
	}
`

const ImageWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 50%;
	height: 100%;
	background-size: cover;

	${breakpoints.mobile} {
		display: none;
	}
`

export { TextImage }
