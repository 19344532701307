import styled, { css } from 'styled-components'
import { breakpoints } from '../../helpers'

export type ButtonProps = {
	display?: 'block' | 'inline-block' | 'flex' | 'inline-flex' | 'none'
	yp?: number
	xp?: number
	br?: number
	variant?: 'primary' | 'secondary' | 'tertiary' | 'transparent'
	displayBlockOnMobile?: boolean
	fixedSize?: boolean
	underline?: boolean
}

const Button = styled.button<ButtonProps>`
	background: none;
	background: ${(props) => props.theme.primary};
	border: 2px solid ${(props) => props.theme.primary};
	color: white;
	border-radius: ${(props) => (props.br !== undefined ? `${props.br}px` : '10px')};
	cursor: pointer;
	display: ${(props) => props.display ?? 'inline-block'};
	font-size: 1rem;
	font-weight: bold;
	letter-spacing: 1.4px;
	padding: ${(props) => (props.yp !== undefined ? `${props.yp}rem` : '0.75rem')}
		${(props) => (props.xp !== undefined ? `${props.yp}rem` : '2rem')};
	outline: 0;
	transition: border-color 300ms, background-color 300ms, color 300ms;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	${(props) =>
		props.fixedSize &&
		css`
			min-width: 290px;
		`}
	
	${(props) =>
		props.displayBlockOnMobile &&
		css`
			${breakpoints.mobile} {
				display: block;
				width: 100%;
			}
		`}
		
	${(props) =>
		props.underline &&
		css`
			text-decoration: underline;
		`}

	&:focus,
	&:hover {
		background-color: white;
		border-color: ${(props) => props.theme.primary};
		color: ${(props) => props.theme.primary};
	}

	&:active {
		background: none;
	}

	${(props) =>
		props.variant === 'secondary' &&
		css`
			background: none;
			border-color: ${(props) => props.theme.primary};
			color: ${(props) => props.theme.primary};

			&:focus,
			&:hover {
				background: ${(props) => props.theme.primary};
				color: white;
			}

			&:active {
				background: none;
			}
		`}
		
	${(props) =>
		props.variant === 'tertiary' &&
		css`
			background: ${(props) => props.theme.tertiary};
			border-color: ${(props) => props.theme.tertiary};

			&:focus,
			&:hover {
				border-color: ${(props) => props.theme.tertiary};
				color: ${(props) => props.theme.tertiary};
			}

			&:active {
				background: none;
			}
		`}

	${(props) =>
		props.variant === 'transparent' &&
		css`
			background: none;
			border-color: transparent;
			color: ${(props) => props.theme.body};
			min-width: auto;

			&:focus,
			&:hover {
				background: none;
				border-color: transparent;
				color: ${(props) => props.theme.primary};
			}

			&:active {
				color: ${(props) => props.theme.primary};
			}
		`}
	
	${(props) =>
		props.disabled &&
		css`
			&,
			&:focus,
			&:hover,
			&:active {
				background: white;
				border-color: ${(props) => props.theme.gray2};
				color: ${(props) => props.theme.gray2};
				cursor: wait;
			}
		`}
`

export { Button }
