import React, { FC } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../../helpers'
import { Message } from './Message'

export type GroupProps = {
	message?: string | JSX.Element
	label: string
	helper?: string
	id: string
}

const Group: FC<GroupProps> = ({ message, label, helper, id, children }) => {
	return (
		<Wrapper>
			<LabelWrapper>
				<Label htmlFor={id}>{label}</Label>
				{helper && <Helper>{helper}</Helper>}
			</LabelWrapper>
			<div>{children}</div>
			<StMessage>{message}</StMessage>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	margin-bottom: 2rem;

	${breakpoints.mobile} {
		flex-flow: column;
		margin-bottom: 1rem;
	}

	& > * {
		flex: 1;
		flex-basis: 33.333%;
	}
`

const LabelWrapper = styled.div`
	padding-right: 2rem;
`

const Label = styled.label`
	display: block;
	letter-spacing: 1px;
	font-family: ${(props) => props.theme.fonts.montserrat};
	font-weight: 600;
	margin-bottom: 0.5rem;
`

const Helper = styled.span`
	display: block;
	font-size: 14px;

	${breakpoints.mobile} {
		margin-bottom: 1rem;
	}
`

const StMessage = styled(Message)`
	margin-left: 1rem;

	${breakpoints.desktop} {
		margin-top: 0.75rem;
	}

	${breakpoints.mobile} {
		text-align: right;
		width: 100%;
		margin-left: 0;
	}
`

export { Group, Label }
