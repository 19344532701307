import React, { forwardRef, HTMLProps } from 'react'
import { Group, GroupProps } from './Group'
import SearchIcon from '../../../img/search.svg'
import styled from 'styled-components'

type Props = {
	name: string
	files?: FileList
} & GroupProps &
	HTMLProps<HTMLInputElement>

const File = forwardRef<HTMLInputElement, Props>(
	({ name, id, message, label, helper, type, as, files, ...rest }, ref) => {
		return (
			<Group label={label} id={name} message={message} helper={helper}>
				<div>
					<Zone>
						<input
							{...rest}
							multiple
							ref={ref}
							name={name}
							type='file'
							style={{
								position: 'absolute',
								overflow: 'hidden',
								clip: 'rect(0 0 0 0)',
								height: '1px',
								width: '1px',
								margin: '-1px',
								padding: '0',
								border: '0',
							}}
						/>
						Browse files
						<SearchIcon />
					</Zone>
				</div>
				{files && files.length > 0 && (
					<List>
						{Array.from(files).map((file, index) => (
							<ListItem key={index}>{file.name}</ListItem>
						))}
					</List>
				)}
			</Group>
		)
	},
)

const Zone = styled.label`
	display: block;
	width: 100%;
	border: 1px solid ${(props) => props.theme.secondary};
	padding: 1rem 3rem 1rem 1.5rem;
	font-family: ${(props) => props.theme.fonts.dosis};
	font-size: 0.975rem;
	letter-spacing: 1.4px;
	position: relative;
	cursor: pointer;

	svg {
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
	}
`

const List = styled.ul`
	padding-top: 1rem;
`

const ListItem = styled.li`
	& + & {
		margin-top: 0.5rem;
	}
`

export { File }
