import React from 'react'
import styled from 'styled-components'

type Props = {
	type?: 'error' | 'success'
}

const Message = styled.div<Props>`
	color: ${(props) => props.theme.error};
	letter-spacing: 1.4px;
	font-family: ${(props) => props.theme.fonts.dosis};
	font-weight: 600;
	font-size: 14px;
	padding: 0.5rem 0;
`

export { Message }
