import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '../theme'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { breakpoints } from '../helpers'

type Props = {
	icon?: JSX.Element
}

const TextBanner: FC<Props> = ({ children, icon }) => {
	return (
		<Wrapper hasIcon={!!icon}>
			<Grid>
				<Row center={'xs'}>
					<Col xs={12} md={7} lg={5}>
						{icon && <div>{icon}</div>}
						{children}
					</Col>
				</Row>
			</Grid>
		</Wrapper>
	)
}

type WrapperProps = {
	hasIcon?: boolean
}

const Wrapper = styled.div<WrapperProps>`
	background: ${theme.quaternary};
	color: ${theme.secondary};
	font-family: ${theme.fonts.dosis};
	font-weight: bold;
	font-size: 36px;
	text-align: center;
	padding: 5rem 0;

	${breakpoints.mobile} {
		font-size: 28px;
		padding: 3rem 0;
	}

	${(props) =>
		props.hasIcon &&
		css`
			padding: 3rem 0 5rem;

			${breakpoints.mobile} {
				padding: 1rem 0 3rem;
			}
		`}

	span {
		color: white;
	}
`

export { TextBanner }
